<template>
  <div class="login">
    <v-row no-gutters>
      <v-col
        cols="12"
        md="7"
        class="login-bg mobile-hidden"
      ></v-col>
      <v-col
        cols="12"
        md="5"
      >
        <div class="text-center">
          <a
            href="https://www.soocel.com"
            class="d-flex justify-center align-center"
          >
            <img
              src="@/assets/images/soocel-logo.png"
              height="45"
              class="logo"
            />
          </a>
        </div>

        <h2 class="text-center">Password Reset</h2>

        <v-form
          class="login-form"
          v-model="valid"
          lazy-validation
          ref="forgetPass"
          v-on:submit.prevent="onSubmit"
        >
          <v-alert
            outlined
            :type="type"
            v-if="alert"
          >{{ alertMsg }}</v-alert>

          <label class="label">Email</label>
          <v-text-field
            v-model="userData.email"
            :rules="forgetPassRules.email"
            placeholder="Enter Email Address"
            solo
          ></v-text-field>

          <v-btn
            depressed
            dark
            large
            block
            color="primary"
            style="width: 252px;"
            :disabled="loading"
            :loading="loading"
            type="submit"
            @click="sendMail()"
          >Submit</v-btn>

          <p class="d-flex justify-space-between mt-4">
            <router-link to="/user/login">Login</router-link>
            <router-link to="/user/register">Register</router-link>
          </p>
        </v-form>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import { forgotPassword } from '@/api/user'

export default {
  data () {
    return {
      loading: false,
      userData: {},
      type: 'error',
      user: {},
      alertMsg: '',
      alert: false,
      errorType: 'error',
      forgetPassRules: {
        email: [
          v => !!v || 'E-mail is required',
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Invalid E-mail'
        ]
      }
    }
  },
  methods: {
    async sendMail () {
      if (this.$refs.forgetPass.validate()) {
        this.alert = false
        try {
          this.loading = true
          const data = await forgotPassword(this.userData)
          if (data.success) {
            this.type = 'success'
            this.alertMsg = data.message
          } else {
            this.type = 'error'
            this.alertMsg = data.message
          }
          this.alert = true
        } catch (error) {
          this.type = 'error'
          this.alertMsg = 'Something went wrong'
          this.alert = true
          console.log(error)
        }
      } else {
        console.log('error submit!!')
        return false
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>
</style>
